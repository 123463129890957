.parallax {
    position: relative;
    height: 70vh;
    overflow: hidden;
    background-image: url('../../assets/timings.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.timing-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #739194a7, transparent);
}

.timing-content {
    z-index: 1;
}

@media screen and (max-width: 600px) {

    .parallax {
        height: 85vh;
    }
}