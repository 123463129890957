.hover-overlay-container {
    position: relative;
    display: inline-block;
    cursor: pointer !important;
}

.hover-overlay {
    display: block;
    width: 100%;
    height: auto;
}

.hover-overlay-container::after {
    content: attr(alt); /* Use the alt attribute for overlay text */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Adjust text color as needed */
    font-size: 1.5em; /* Adjust font size as needed */
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-overlay-container:hover::after {
    opacity: 1;
}
