.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff00cc;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #3333992c,
            #ff00cc32);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #3333992c,
            #ff00cc32);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.glass-card {
    background: white;
    background: linear-gradient(to right bottom,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.2));
    backdrop-filter: blur(1rem);
    width: 60%;
    height: 60%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.circle1,
.circle2 {
    background: white;
    background: linear-gradient(to right bottom,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.1));
    height: 12rem;
    width: 12rem;
    position: relative;
    border-radius: 50%;
}

.circle1 {
    top: -60%;
    left: 1%;
    transform: translate(-10%, -10%);
}

.circle2 {
    top: -44%;
    left: 86%;
    transform: translate(-50%, -50%);
}

.about-me-content {
    letter-spacing: 0.05rem;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    .circle1,
    .circle2 {
        height: 8rem;
        width: 8rem;
    }

    .circle2 {
        top: -36%;
    }

    .about-me-content {
        letter-spacing: 0.014rem;
    }

    .container-about{
        flex-wrap: wrap !important;
    }
    .about-image-container{
        width: 95%;
    }
}